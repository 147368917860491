<template>
    <!-- 社会责任 -->
    <div class="Duty">
        <div class="header">
            <Head :add="routeAdd"></Head>
        </div>
        <div class="main">
            <p class="title abs">社会责任</p>
            <div class="text_box abs">
                <p class="text">城银股份接下来将会以先进为榜样，勇于开拓创新，加大技术研发，不断提高</p>
                <p class="text mid">管理水平，增强竞争能力，创造一流业绩，树立良好民营企业形象，共同推动民营经济高质量发展，</p>
                <p class="text">奋力书写民营经济发展的新篇章，为经济社会发展做更多贡献。</p>
            </div>
            <div class="item_1 abs">
                <img src="../assets/images/duty_1.png" />
            </div>
            <div class="item_2 abs">
                <img src="../assets/images/duty_2.png" />
            </div>
            <div class="item_3 abs">
                <img src="../assets/images/duty_3.png" />
            </div>
        </div>
    </div>
</template>

<script>
import Head from "../components/Head.vue";
export default {
    components: {
        Head,
    },
    data() {
        return {
            routeAdd: "",
        };
    },
    created() {
        this.routeAdd = this.$route.name;
    },
};
</script>

<style lang="scss" scoped>
.Duty {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: url("../assets/images/duty_background.png") no-repeat;
    .header {
        height: 6.25rem;
    }
    .main {
        flex: 1;
        position: relative;
        color: #fff;
        .abs {
            position: absolute;
        }
        .title {
            top: 4.94rem;
            left: 23.94rem;
            font-size: 36px;
            font-weight: bold;
        }
        .text {
            font-size: 16px;
            font-weight: bold;
        }
        .mid {
            margin: 1rem 0;
        }
        .text_box {
            top: 9.19rem;
            left: 23.88rem;
        }
        .item_1 {
            top: 18.38rem;
            left: 31.56rem;
        }
        .item_2 {
            top: 18.38rem;
            left: 51.19rem;
        }
        .item_3 {
            top: 18.38rem;
            left: 72.52rem;
        }
    }
}
</style>
